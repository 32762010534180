import { format } from "date-fns";
import { ja, enUS, zhCN } from "date-fns/locale";
import { useLocaleContext } from "../contexts/LocaleProvider";

export type DateTimeOptions = {
  hour?: boolean;
  minute?: boolean;
  second?: boolean;
  year?: boolean;
  month?: boolean;
  weekday?: boolean | "long";
  day?: boolean;
};

type LocaleType = keyof typeof patterns.date;

const localeMap: Record<string, Locale> = {
  ja,
  en: enUS,
  zh: zhCN,
  default: ja,
};

const patterns = {
  date: {
    ja: "yyyy/MM/dd",
    en: "MMM d, yyyy",
    zh: "yyyy/MM/dd",
    default: "yyyy/MM/dd",
  },
  time: {
    en: {
      standard: "h:mm a · ",
      withSeconds: "h:mm:ss a · ",
    },
    ja: {
      standard: "H:mm",
      withSeconds: "H:mm:ss",
    },
    zh: {
      standard: "H:mm",
      withSeconds: "H:mm:ss",
    },
    default: {
      standard: "H:mm",
      withSeconds: "H:mm:ss",
    },
  },
} as const;

const REGEX_YEAR_EN = /, yyyy/;
const REGEX_YEAR_DEFAULT = /^yyyy\/|^yyyy |, yyyy/;
const REGEX_MONTH_EN = /MMM /;
const REGEX_MONTH_DEFAULT = /M+\//;
const REGEX_DAY_EN = / d/;
const REGEX_DAY_DEFAULT = /\/dd|\/d/;

export const getDatePattern = (basePattern: string, options?: DateTimeOptions, locale?: LocaleType): string => {
  if (!options) return basePattern;
  if (isAllDateHidden(options)) return "";

  let pattern = basePattern;

  if (options.year === false) {
    pattern = removeYear(pattern, locale);
    pattern = fixPartialDate(pattern, options, locale);
  }

  if (options.month === false) {
    pattern = removeMonth(pattern, locale);
  }

  if (options.day === false) {
    pattern = removeDay(pattern, locale);
  }

  if (options.weekday) {
    pattern = addWeekdayPattern(pattern, options);
  }

  return pattern;
};

function isAllDateHidden(options: DateTimeOptions): boolean {
  return options.year === false && options.month === false && options.day === false;
}

function removeYear(pattern: string, locale?: LocaleType): string {
  return locale === "en"
    ? pattern.replace(REGEX_YEAR_EN, "")
    : pattern.replace(REGEX_YEAR_DEFAULT, "").replace(/MM/g, "M");
}

function removeMonth(pattern: string, locale?: LocaleType): string {
  return locale === "en" ? pattern.replace(REGEX_MONTH_EN, "") : pattern.replace(REGEX_MONTH_DEFAULT, "");
}

function removeDay(pattern: string, locale?: LocaleType): string {
  return locale === "en" ? pattern.replace(REGEX_DAY_EN, "") : pattern.replace(REGEX_DAY_DEFAULT, "");
}

function fixPartialDate(pattern: string, options: DateTimeOptions, locale?: LocaleType): string {
  if (locale === "en") {
    return pattern;
  }
  if (options.month === false && options.day !== false) {
    return pattern.replace(/\/d/, "日");
  }
  if (options.day === false && options.month !== false) {
    return pattern.replace(/M/, "M月");
  }
  return pattern.replace(/\//, "/");
}

function addWeekdayPattern(pattern: string, options?: DateTimeOptions): string {
  if (!options?.weekday) return pattern;

  const weekdayFormat = options.weekday === "long" ? "EEEE" : "E";
  const locale = pattern.includes("MMM") ? `${weekdayFormat}, ` : ` ${weekdayFormat}`;
  return pattern.includes("MMM") ? `${locale}${pattern}` : `${pattern}${locale}`;
}

export const getTimePattern = (locale: LocaleType, options?: DateTimeOptions): string | null => {
  if (!options) {
    return patterns.time[locale].standard;
  }

  if (options.hour === false) {
    return null;
  }

  if (options.second === true) {
    return patterns.time[locale].withSeconds;
  }

  return patterns.time[locale].standard;
};

function removeTrailingDotIfEmptyDate(datePattern: string, timePattern: string): string {
  if (!datePattern) {
    return timePattern.replace(/\s*·\s*$/, "");
  }
  return `${timePattern}${datePattern}`;
}

export const getFormatPattern = (locale: LocaleType, options?: DateTimeOptions): string => {
  const datePattern =
    locale === "en" && options?.year === false
      ? getDatePattern("MMM d", options, locale)
      : getDatePattern(patterns.date[locale], options, locale);

  const timePatternOrNull = getTimePattern(locale, options);
  if (!timePatternOrNull) return datePattern;

  const timePattern = removeTrailingDotIfEmptyDate(datePattern, timePatternOrNull);

  return locale === "en" ? timePattern : [datePattern, timePatternOrNull].join(" ");
};

export const useIntlDateTime = () => {
  const { locale } = useLocaleContext();

  return (date: string | Date, options?: DateTimeOptions) => {
    const parsedDate = typeof date === "string" ? new Date(date) : date;
    const currentLocale = (locale in patterns.date ? locale : "default") as LocaleType;
    const pattern = getFormatPattern(currentLocale, options);
    return format(parsedDate, pattern, { locale: localeMap[currentLocale] });
  };
};
